<div>
<mat-card class="main-card">
<mat-card-header>
    <div class="header-text w-full"> <h4>SEARCH FOR A CUSTOMER BASED FILE / VOLUME DETAILS</h4></div>
    <div class="additional-menu float-right">
        <button [matMenuTriggerFor]="menu" aria-label="Options"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="generateAuthPage()" [disabled]="!hasSearchActionOccurred || noSearchResults">
                <mat-icon>assignment</mat-icon><span>Generate Auth.Page</span>
            </button>
        </mat-menu>
    </div>
</mat-card-header>
<mat-card-content class="main-card-content">
    <form #detailSearchForm="ngForm" (ngSubmit)="onSearch(detailSearchForm.value)">
        <div class="row mb-4">
            <div class="col-md-3">
                <label for="inputAccountNumber" class="form-label">Account Number</label>
                <div>
                    <input type="text" name="inputAccountNumber" id="inputAccountNumber" #accountNumber="ngModel"
                        [(ngModel)]="searchRequestModel.accountNumber" class="form-control" />
                </div>
            </div>
            <div class="col-md-3">
                <label for="inputPartySiteNumber" class="form-label">Party Site Number</label>
                <div>
                    <input type="text" name="inputPartySiteNumber" id="inputPartySiteNumber" #partysiteNumber="ngModel"
                        [(ngModel)]="searchRequestModel.partySiteNumber" class="form-control" />
                </div>
            </div>
            <div class="col-md-3">
                <label for="filterBy" class="form-label">FilterBy Status</label>
                <div>
                    <mat-select id="filterBy" name="filterBy" [(ngModel)]="searchRequestModel.filterBy" class="form-control">
                        <mat-option [value]="options.value" *ngFor="let options of statusOptions">{{options.key}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-md-3">
                <label class="form-label">&nbsp;</label>
                <div>
                    <button class="button form-button mr-2 float-right" type="submit">Search</button>
                    <button class="button form-clear-button mr-2 float-right" type='button' (click)="resetForm(detailSearchForm)">Clear</button>
                </div>
            </div>
        </div>
        <div class="row" style="display:none;">
            <div class="col-md-3">
                <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label" name="exactSearch" id="exactSearch"
                    [(ngModel)]="searchRequestModel.exactSearch">Exact Search</mat-checkbox>
            </div>
        </div>
    </form>
    <div class="w-full m-0" *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
        <hr />
        <app-paginator (sortingChange)='onSortByChange($event)' (viewChange)='onViewChange($event)' (paginationChange)='onSortPaginationChange($event)'
                       [isTableView]="isTableView" [length]="searchResultCount" [sortByOptions]="sortOptions" [showSortBy]="true"
                       [filterByOptions]="statusOptions" [showFilterBy]="false" [showPageSize]="true" [defaultPageSize]="pageSize" [pageIndex]="0">
        </app-paginator>
    </div>
</mat-card-content>
</mat-card>
<ng-template #accFileVolSearchContainer></ng-template>
<div *ngIf="noSearchResults && hasSearchActionOccurred">
    <div class="ml-20">
        <div class="flex flex-col justify-center max-w-full mx-auto my-3">
            <div class="flex"><img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" /></div>
            <div class="flex"><div class="feedback-header mx-auto title-no-data">No Details Found</div></div>
        </div>
    </div>
</div>
<div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
    <div class="search-details-container">
        <div *ngIf="searchResult && searchResult.results && !isTableView">
            <app-account-file-vol-searchresult [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort">
            </app-account-file-vol-searchresult>
        </div>
        <div *ngIf="searchResult && searchResult.results && isTableView">
            <app-account-file-vol-searchresult [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort" (sortingChangeAction)="onSortingChange($event)">
            </app-account-file-vol-searchresult>
        </div>
    </div>
</div>
<div class="loader-center" *ngIf="loading">
    <div id="loader" class="loader"></div>
</div>
</div>