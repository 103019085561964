<mat-sidenav-container class="example-container">
<mat-sidenav #sideNav mode="over" class="sideNav" id="sideNav" (closedStart)="sideNavClosedStart()" opened="false" position="end">
    <app-side-nav></app-side-nav>
</mat-sidenav>
<mat-sidenav-content [style.backgroundColor]="backgroundColor">
    <div class="h-screen">
        <div class="min-h-91">
            <header class="fixed z-10000 w-full top-0 bottom-0 h-20">
                <app-header></app-header>
            </header>
            <div class="wrapper p-6 pr-0">
                <nav joyrideStep="step1" title="Navigation Bar" text="User can navigate to different pages of the application from here" id="sidebar" *ngIf="userLoggedIn">
                    <div class="absolute bottom-0">
                        <button (click)="isExpanded = !isExpanded" color="primary" class="menu-toggle-button text-header">
                            <fa-icon *ngIf="isExpanded" icon="arrow-alt-circle-left" class="text-2xl text-center"></fa-icon>
                            <fa-icon *ngIf="!isExpanded" icon="arrow-alt-circle-right" class="text-2xl text-center"></fa-icon>
                        </button>
                    </div>
                    <mat-divider></mat-divider>
                    <mat-nav-list class="text-black">
                        <mat-list-item routerLink="/file-details" routerLinkActive="nav-active" joyrideStep="navigationIcon1" title="File Details"
                                       text="This option allows the user to search, view, edit, withdraw and delete any file related details">
                            <fa-icon mat-list-icon icon="file" class="text-2xl text-center text-header"></fa-icon>
                            <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">File Details</h4>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item routerLink="/file-volume" routerLinkActive="nav-active" joyrideStep="navigationIcon2" title="File Volume Details"
                                       text="This option allows the user to search, view, edit, withdraw and delete any file - volume related details">
                            <fa-icon mat-list-icon icon="flask" class="text-2xl text-center text-header"></fa-icon>
                            <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">File Volume Details</h4>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item routerLink="/manufacturerDetails" routerLinkActive="nav-active" joyrideStep="navigationIcon3" title="Manufacturer Details"
                                       text="This option allows the user to search, view, edit, withdraw and delete any manufacturer related details">
                            <fa-icon mat-list-icon icon="tools" class="text-2xl text-center text-header"></fa-icon>
                            <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">Manufacturer Details</h4>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item routerLink="/listeeDetails" routerLinkActive="nav-active" joyrideStep="navigationIcon4" title="Listee Association"
                                       text="This option allows the user to search, view, edit, withdraw and delete any Listee related details">
                            <fa-icon mat-list-icon icon="building" class="text-2xl text-center text-header"></fa-icon>
                            <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">Listee Association</h4>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item routerLink="/icDetails" routerLinkActive="nav-active" joyrideStep="navigationIcon5" title="IC Details"
                                       text="This option allows the user to search any IC related details">
                            <fa-icon mat-list-icon icon="certificate" class="text-2xl text-header"></fa-icon>
                            <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">IC Details</h4>
                        </mat-list-item>
                        <mat-list-item routerLink="/custaccount-filevol-details" routerLinkActive="nav-active" joyrideStep="navigationIcon6" title="Cust.Account - File/Volume Details"
                                       text="This option allows the user to search any account, psn related of file/volume details">
                            <fa-icon mat-list-icon icon="address-card" class="text-2xl text-header"></fa-icon>
                            <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">Cust Account <br />File / Volume Details</h4>
                        </mat-list-item>
                        <!-- TO-DO Temporarily disabled, until the development is done
                        <mat-list-item routerLink="/productCcn" routerLinkActive="nav-active" joyrideStep="navigationIcon5" title="Product Type CCN Mapping"
                           text="This option allows the user to search, view and edit any Production ccn related details">
                           <fa-icon mat-list-icon icon="boxes" class="text-2xl text-center text-header"></fa-icon>
                           <h4 mat-line *ngIf="isExpanded" class="font-bold text-sm mt-2">Product Type CCN Mapping</h4>
                        </mat-list-item>-->
                    </mat-nav-list>
                </nav>
                <div class="main-content max-w-full" [ngStyle]="{'margin-left': isExpanded ? '8.5rem' : '0rem'}">
                    <!-- [ngClass]="{'nav-minimized': !isExpanded, 'nav-expanded': isExpanded}"> -->
                    <div class="pt-13">
                        <div><app-toastnotification [message]="message"></app-toastnotification></div>
                        <main role="main" class="main">
                            <!--<app-loader></app-loader>-->
                            <div><router-outlet></router-outlet></div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
        <footer [ngStyle]="{'margin-left': userLoggedIn ? (isExpanded ? '13.6rem' : '2rem') : '0rem'}" class="footer mt-auto">
            <app-footer></app-footer>
        </footer>
    </div>
</mat-sidenav-content>
</mat-sidenav-container>