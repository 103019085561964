import { Component, OnInit, AfterViewInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { GenerateAuthPageModel } from '../../shared/utility.model';
import { AccLevelFileVolService } from '../account-file-vol-detail.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { DataSearchRequest, DataResultSummary, DataSearchResultModel } from '../account-file-vol-detail.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { LoaderComponent } from '../../shared/loader/loader.component';

@Component({
    selector: 'app-account-file-vol-search',
    templateUrl: './account-file-vol-search.component.html',
    styleUrls: ['./account-file-vol-search.component.scss']
})

export class AccountFileVolSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('accFileVolSearchContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

    loading = false;
    isSearch = false;
    isSearching = false;
    showSpinner = false;
    isTableView = false;
    noSearchResults = false;
    hasSearchActionOccurred = false;
    searchResultCount: number;

    refreshDataSubscription: Subscription;
    pageSize = Constants.DEFAULT_PAGE_SIZE_50;
    sortOptions = Constants.CUST_ACC_FILE_VOL_DETAIL_SORT_OPT;
    prevSearchRequest: DataSearchRequest = {} as DataSearchRequest;
    statusOptions = Constants.SHOW_BY_OPTIONS;
    searchResult: DataSearchResultModel;

    currentSort = { active: '', direction: '' };
    searchRequestModel: DataSearchRequest = {
        accountNumber: '',
        partySiteNumber: '',
        exactSearch: true,
        pageSize: Constants.DEFAULT_PAGE_SIZE_50,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ACTIVE_SHORTCODE,
        pageNumber: 1
    };


    constructor(
        private dataService: DataService,
        private resolver: ComponentFactoryResolver,
        private searchService: AccLevelFileVolService,
        private toastService: ToastNotificationService,
        private loadingSpinnerService: LoadingSpinnerService
    ) {
        this.refreshDataSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            if (this.dataService.isEmpty(this.searchRequestModel)) { this.getSearchResult(this.searchRequestModel); }
        });
    }

    onSortPaginationChange = (onPageEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.searchRequestModel);
        if (result) { this.searchRequestModel.pageNumber = onPageEvent.page; }
        else { this.searchRequestModel.pageNumber = 1; }
        this.searchRequestModel.pageSize = onPageEvent.pageSize;
        this.getSearchResult(this.searchRequestModel);
    }

    onSortByChange = (onPageEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.searchRequestModel);
        if (!result) { this.searchRequestModel.pageNumber = 1; }
        this.searchRequestModel.sortBy = onPageEvent.sortBy.split('-')[0];
        this.searchRequestModel.sortDirection = onPageEvent.sortBy.split('-')[1];
        this.getSearchResult(this.searchRequestModel);
    }

    onSortingChange = (sortingEvent: DataSearchRequest) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.searchRequestModel);
        if (!result) { this.searchRequestModel.pageNumber = 1; }
        this.searchRequestModel.sortBy = sortingEvent.sortBy;
        this.searchRequestModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.searchRequestModel);
    }

    onViewChange = (changeView: Paginator) => { this.isTableView = changeView.isTableView; }

    onSearch = (formValues) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.searchRequestModel);
        if (!this.hasSearchActionOccurred) {
            this.searchRequestModel.pageNumber = 1;
            this.searchRequestModel.pageSize = Constants.DEFAULT_PAGE_SIZE_50;
            this.searchRequestModel.sortDirection = this.sortOptions[0].value.split('-')[1];
            this.searchRequestModel.sortBy = this.sortOptions[0].value.split('-')[0];
        }
        else if (this.hasSearchActionOccurred && !result) { this.searchRequestModel.pageNumber = 1; }
        this.getSearchResult(formValues);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.prevSearchRequest = Object.assign({}, this.searchRequestModel);
            this.dataService.setOption(Constants.CUST_ACC_SEARCH_FILEVOL_REQUEST_OBJECT, this.searchRequestModel);
            this.currentSort.direction = this.searchRequestModel.sortDirection;
            this.currentSort.active = this.searchRequestModel.sortBy;

            this.searchService.getSearchResult(this.searchRequestModel).subscribe((searchResult) => {
                componentRef.destroy();
                this.showSpinner = false;
                this.searchResult = searchResult;
                this.searchResultCount = searchResult.paging.totalRecords;
                this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);

                if (this.searchResultCount === 0) {
                    this.dataService.navigateToPageTop();
                    const type = ToastNotificationType.infoBlue;
                    const placement = ToastNotificationPlacement.Body;
                    const errorMessage = 'No Data found for the specified search criteria';
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isSearch = false;
                }
                else {
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = false;
                    this.isSearch = true;
                }
            }, (error) => {
                this.isSearch = false;
                this.showSpinner = false;
                this.searchResultCount = 0;
                this.dataService.navigateToPageTop();
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const errorMessage = `Error while retrieving the data`;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.hasSearchActionOccurred = true;
                this.noSearchResults = true;
                componentRef.destroy();
            });
        }
        else {
            this.isSearch = false;
            this.showSpinner = false;
            this.searchResultCount = 0;
            this.dataService.navigateToPageTop();
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const errorMessage = 'Please enter the search criteria in the form below';
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
        }
    }

    resetForm(detailSearchForm: NgForm): void {
        detailSearchForm.resetForm({
            inputAccountNumber: '',
            inputPartySiteNumber: '',
            exactSearch: true,
            filterBy: Constants.ACTIVE_SHORTCODE
        });

        this.isSearch = false;
        this.isTableView = false;
        this.searchResultCount = 0;
        this.hasSearchActionOccurred = false;
        this.searchResult = {} as DataSearchResultModel;
        this.prevSearchRequest = {} as DataSearchRequest;
        this.dataService.setOption(Constants.CUST_ACC_SEARCH_FILEVOL_REQUEST_OBJECT, {});
    }

    generateAuthPage = () => {
        const empId = this.dataService.getLastUpdatedBy();
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const fileVolRec = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        const componentRef = this.entry.createComponent(factory);
        if (fileVolRec != undefined && fileVolRec.length > 0) {
            const request: GenerateAuthPageModel = { inputType: "FILE_VOL", empId: empId, records: fileVolRec };
            this.dataService.generateAuthPage(request).subscribe(result => {                
                componentRef.destroy();
                if (result["OverallStatus"] == Constants.SUCCESS) {
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const successMessage = `Successfully generated the Auth page for selected file volume(s)`;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);
                    this.searchService.onRefreshSearchResult();
                }
                else {
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    var errorMessage = `Error while generating the Auth page for selected file volume(s)`;
                    if (result["ErrorMessage"] != "") { errorMessage = result["ErrorMessage"]; }
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                componentRef.destroy();
                const errorMessage = `Error while generating the Auth page for selected file volume(s)`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }
        else {
            componentRef.destroy();
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const errorMessage = `Please select the file volume(s) to proceed.`;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    ngOnInit(): void {
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        const obj = this.dataService.getOption(Constants.CUST_ACC_SEARCH_FILEVOL_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.CUST_ACC_SEARCH_FILEVOL_REQUEST_OBJECT) && Object.keys(obj).length > 0) {
            this.searchRequestModel = this.dataService.getOption(Constants.CUST_ACC_SEARCH_FILEVOL_REQUEST_OBJECT);
            if (this.dataService.isEmpty(this.searchRequestModel)) { this.onSearch(this.searchRequestModel); }
        }
    }

    ngAfterViewInit(): void { this.loadingSpinnerService.removeLoadingSpinner(); }

    ngOnDestroy(): void { if (this.refreshDataSubscription) { this.refreshDataSubscription.unsubscribe(); } }
}
