import { JoyrideModule } from 'ngx-joyride';
import { AppComponent } from './app.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AppRoutingModule } from './app-routing.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { MsAdalAngular6Module, MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { LoaderComponent } from './shared/loader/loader.component';
import { dateFormats } from './shared/date-format/date-format.model';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { SideNavComponent } from './shared/side-nav/side-nav.component';
import { AppInsightsService } from './app-insights/app-insights.service';
import { BackgroundDirective } from './shared/background-blue.directive';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ClientSettingsService } from './core/services/client-settings.service';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { ModalDialogComponent } from './shared/modal-dialog/modal-dialog.component';
import { NoDataFoundComponent } from './shared/no-data-found/no-data-found.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { SidenavInsertionDirective } from './shared/side-nav/sidenav-insertion.directive';
import { InsertAuthTokenInterceptor } from './core/interceptors/insert-auth-token.interceptor';
import { InformationWindowComponent } from './shared/informationwindow/informationwindow.component';
import { ToastnotificationComponent } from './shared/toastnotification/toastnotification.component';
import { ToastNotificationService } from './shared/toastnotification/toastnotification.service';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { SideNavLoaderComponent } from './shared/side-nav-loader/side-nav-loader.component';
import { InitializeApp, MsAdalAngular6ConfigFactory } from './shared/initializeapp-function';

import { UtilityModule } from './utility';
import { MaterialModule } from './material';
import { LayoutModule } from './layout/layout.module';
import { ListeeModule } from './listee/listee.module';
import { IcDetailModule } from './ic-details/ic-detail.module';
import { MfrDetailModule } from './mfr-detail/mfr-detail.module';
import { FileVolumeModule } from './file-volume/file-volume.module';
import { FileDetailsModule } from './file-details/file-details.module';
import { ProductCcnModule } from './product-ccn-association/product-ccn.module';
import { CustomerDetailsModule } from './customer-details/customer-details.module';
import { CustAccountFileVolDetailsModule } from './account-file-vol-details/account-file-vol-detail.module';


@NgModule({
    declarations: [
        AppComponent, LoaderComponent, SideNavComponent, LoggedOutComponent,
        PageNotFoundComponent, SideNavLoaderComponent, LoadingSpinnerComponent,
        BackgroundDirective, ModalDialogComponent, NoDataFoundComponent, UnauthorizedComponent,
        ToastnotificationComponent, SidenavInsertionDirective, InformationWindowComponent
    ],
    imports: [
        NgbModule, FormsModule, LayoutModule, ListeeModule, UtilityModule, BrowserModule,
        MatDatepickerModule, IcDetailModule, MaterialModule, NgbAlertModule, MfrDetailModule,
        ProductCcnModule, AppRoutingModule, HttpClientModule, FileVolumeModule, FontAwesomeModule,
        FileDetailsModule, ReactiveFormsModule, BrowserAnimationsModule, CustAccountFileVolDetailsModule,
        NgIdleKeepaliveModule.forRoot(), JoyrideModule.forRoot(), CustomerDetailsModule, MsAdalAngular6Module        
    ],
    providers: [
        AppInsightsService,
        AuthenticationGuard,
        MsAdalAngular6Service,
        ToastNotificationService,
        MatNativeDateModule,
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [ClientSettingsService],
            useFactory: InitializeApp
        },
        {
            provide: 'adalConfig',
            useFactory: MsAdalAngular6ConfigFactory,
            deps: []
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InsertAuthTokenInterceptor,
            multi: true
        },
        { provide: DateAdapter, useClass: MomentDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: dateFormats }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(library: FaIconLibrary) { library.addIconPacks(fas); }
}
