import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientSettingsService } from '../core/services/client-settings.service';
import { DataSearchRequest, DataSearchResultModel } from './account-file-vol-detail.model';

@Injectable({ providedIn: 'root' })

export class AccLevelFileVolService {
    private fileApiEndPoint: string;
    private fileVolumeEndPoint: string;
    private mfrApiEndPoint: string;
    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();
    private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private clientSetting: ClientSettingsService, private http: HttpClient) {
        this.fileApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/File/`;
        this.fileVolumeEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/FileVolume/`;
        this.mfrApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/MfrAssociation/`;
    }

    onRefreshSearchResult() { this.refreshSearchResult.next(); }

    getSearchResult(searchRequest: DataSearchRequest): Observable<DataSearchResultModel> {
        const searchResult = this.http.post<DataSearchResultModel>(`${this.fileVolumeEndPoint}GetCustRelatedFileVolDetails`, searchRequest, this.httpOptions).pipe();
        return searchResult;
    }

    checkIfAgencyFile = (fileRecID: number): Observable<boolean> => {
        return this.http.post<boolean>(`${this.fileApiEndPoint}checkIfAgencyFile`, fileRecID, this.httpOptions).pipe();
    }

    getActiveMfr = (fileVolRecID: number): Observable<boolean> => {
        const isActiveMfr = this.http.post<boolean>(`${this.mfrApiEndPoint}getActiveMfr`, fileVolRecID, this.httpOptions).pipe();
        return isActiveMfr;
    }
}