import { Component, ElementRef, Input, SimpleChanges, OnInit, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { Paginator } from './paginator.model';
import { Constants } from '../../constants';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})

export class PaginatorComponent implements OnInit, OnChanges {
    @ViewChild('mat-paginator-range-label') matElement: ElementRef;

    page = 1;
    pageSize: number;
    pageRangeLabel = '';
    pageSortByOptions = [];
    pageShowByOptions = [];
    numberOfPages: Number[] = [];
    pageSizeOptions = Constants.PAGE_SIZE_OPTION;
    filterBy: string = Constants.emptyKeyValue[0].value;
    sortBy: string = Constants.emptyKeyValue[0].value;
    showRangePageLabel: boolean = true;
    viewType: string = 'Card';

    @Input() length;
    @Input() pageIndex = 0;
    @Input() showSortBy = false;
    @Input() showFilterBy = false;
    @Input() showPageSize = false;
    @Input() defaultPageSize = 25;
    @Input() set sortByOptions(dataValue: any) {
        this.pageSortByOptions = dataValue;
        this.sortBy = dataValue?.[0]?.value ?? "";
    }
    @Input() set filterByOptions(dataValue: any) {
        this.pageShowByOptions = dataValue;
        this.filterBy = dataValue?.[0]?.value ?? "";
    }
    @Input() set isTableView(dataValue: boolean) {
        this.viewType = dataValue ? 'Table' : 'Card';
    }

    @Output() paginationChange: EventEmitter<Paginator> = new EventEmitter();
    @Output() sortingChange: EventEmitter<Paginator> = new EventEmitter();
    @Output() viewChange: EventEmitter<Paginator> = new EventEmitter();

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void { this.setDefaultPaginatorValues(); }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.length) {
            if (changes.length.previousValue !== changes.length.currentValue) {
                this.calculateNoOfPage();
            }
        }
    }

    calculateNoOfPage() {
        let noOfPage;
        this.page = 1;
        this.numberOfPages = [];
        if (this.length === 0) {
            noOfPage = 1;
            this.pageRangeLabel = `0 - ${this.length} of ${this.length}`;
        }
        else if (this.length < this.pageSize) {
            noOfPage = 1;
            this.pageRangeLabel = `1 - ${this.length} of ${this.length}`;
        }
        else {
            noOfPage = Math.ceil(this.length / this.pageSize);
            this.pageRangeLabel = `1 - ${this.pageSize} of ${this.length}`;
        }
        for (let i = 1; i <= noOfPage; i++) { this.numberOfPages.push(i); }
    }

    onViewTypeChange(event) {
        this.viewChange.emit({
            page: this.page,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            filterBy: this.filterBy,
            isTableView: event.value === 'Table' ? true : false
        });
    }

    onSortingChange() {
        this.sortingChange.emit({
            page: 1,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            filterBy: this.filterBy,
            isTableView: this.viewType === 'Table' ? true : false
        });
    }


    // This method will recalculate the number of pages
    // based on the page size choosen @param value 
    async onPageSizeChange(value) {
        this.pageSize = value;
        await this.calculateNoOfPage();
        this.paginationChange.emit({
            page: 1,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            filterBy: this.filterBy,
            isTableView: this.viewType === 'Table' ? true : false
        });
    }

    onPageChange(value) {
        if (value > 0 && value <= this.numberOfPages[this.numberOfPages.length - 1]) {
            this.page = value;
            const currentRecord = (this.page - 1) * this.pageSize + 1;
            let lastRecord = this.page * this.pageSize;
            if (lastRecord > this.length) { lastRecord = this.length; }
            this.pageRangeLabel = `${currentRecord} - ${lastRecord} of ${this.length}`;
        }
        this.paginationChange.emit({
            page: this.page,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            filterBy: this.filterBy,
            isTableView: this.viewType === 'Table' ? true : false
        });
    }

    // This method will set the default values for the page size and
    // calculate the total number of pages
    setDefaultPaginatorValues() {
        let noOfPage;
        this.numberOfPages = [];
        this.pageSize = this.defaultPageSize;
        this.viewType = this.isTableView ? 'Table' : 'Card';

        if (this.length === 0) {
            noOfPage = 1;
            this.pageRangeLabel = `0 - ${this.length} of ${this.length}`;
        }
        else if (this.length < this.pageSize) {
            noOfPage = 1;
            this.pageRangeLabel = `1 - ${this.length} of ${this.length}`;
        }
        else {
            noOfPage = Math.ceil(this.length / this.pageSize);
            this.pageRangeLabel = `1 - ${this.pageSize} of ${this.length}`;
        }
        for (let i = 1; i <= noOfPage; i++) { this.numberOfPages.push(i); }
    }

    onPaginateChange(event) {
        // const dom: HTMLElement = this.elementRef.nativeElement;
        // const elements = dom.querySelectorAll('.mat-paginator-range-label');
        // const matElement = elements[0].innerHTML;
        // console.log(elements);
        // console.log(this.matElement);
        // elements[0].innerHTML = 'Page: ' + this.page.toString() + '(' + matElement + ')';
    }
}