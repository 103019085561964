import { NgModule } from '@angular/core';
import { UtilityModule } from '../utility';
import { MaterialModule } from '../material';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccountFileVolSearchResultComponent } from './account-file-vol-result/account-file-vol-searchresult.component'
import { AccountFileVolSearchComponent } from './account-file-vol-search/account-file-vol-search.component'

@NgModule({
    declarations: [AccountFileVolSearchComponent, AccountFileVolSearchResultComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule,
        FontAwesomeModule, AppRoutingModule, UtilityModule]
})

export class CustAccountFileVolDetailsModule { }
